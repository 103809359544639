import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"
import Button from '@bit/azheng.joshua-tree.button';
import CTA from "./CTA"
// import { Image, Video, Transformation, CloudinaryContext } from 'cloudinary-react';

var classNames = require("classnames")

class SideCTA extends React.Component {
  constructor(props) {
    super(props)
  }

  render() {
    return (
      <CTA
        className={this.props.className ? this.props.className : ""}
        sideColumnIs={this.props.sideColumnIs}
        headingColumns={
          <div className="column">
            <h7 className="cta-subheading">{this.props.subheading}</h7>
            <h5 className="cta-heading">{this.props.heading}</h5>
          </div>
        }
        textColumns={
          <div className="column">
            <p>{this.props.paragraph}</p>
          </div>
        }
        buttonColumns={
          <div className="column">
            <Button
              contained
              href={this.props.buttonUrl}
              buttonText={this.props.buttonText}
              download={this.props.download ? this.props.download : false}
            />
          </div>
        }
      />
    )
  }
}

SideCTA.propTypes = {
  className: PropTypes.string,
  heading: PropTypes.string,
  subheading: PropTypes.string,
  paragraph: PropTypes.string,
  buttonUrl: PropTypes.string,
  buttonText: PropTypes.string,
  sideColumnIs: PropTypes.number,
}

export default SideCTA
